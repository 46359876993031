.features1{
    height: 90vh;
    background-color: rgb(255, 255, 255);
    //background-color: #FEEDED;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12vh;
    overflow-y: visible;
    .title{
        //font-size: 50px;
        font-size: 6vh;
        font-weight: 700;
        color: rgb(89, 89, 89);
        font-family: 'Raleway', sans-serif;
        width: 60%;
        text-align: center;
        margin-bottom: 20px;
        @media screen and (max-width: 1100px) {
            width: 80%;
            margin-top: 5vh;
        }
        @media screen and (max-width: 700px) {
            width: 90%;
            margin-top: 8vh;
            font-size: 7vw;
            margin-bottom: 2vh;
        }
    }
    .sub__title{
        font-family: 'Open Sans', sans-serif;
        width: 40%;
        text-align: center;
        font-size: 16px;
        @media screen and (max-width: 1100px) {
            font-size: 1.8vw;
            width: 70%;
            margin-top: 1vh;
            margin-bottom: 4vh;
        }
        @media screen and (max-width: 700px) {
            width: 80%;
            margin-bottom: 6vh;
            font-size: 3vw;
        }
    }
    .features{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //background-color: rgb(244, 244, 244);
        //background-color: rgb(235, 235, 235);
        background-color: rgb(255, 255, 255);
        width: 60%;
        height: 100%;
        @media screen and (max-width: 1400px) {
            width: 70%;
        }
        @media screen and (max-width: 1200px) {
            width: 80%;
        }
        @media screen and (max-width: 1100px) {
            width: 85%;
        }
        //margin-top: 5vh;
        .left__container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            //background-color: rgba(255, 228, 196, 0.656);
            height: 50%;
            .feature__list{
                //background-color: rgba(144, 238, 144, 0.536);
                display: flex;
                flex-direction: row;
                width: 75%;
                flex-wrap: wrap;
                gap: 20px;
                margin-bottom: 5%;
                .feature__item{
                    background-color: rgb(254, 254, 254);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 200px;
                    border-radius: 8px;
                    padding: 10px;
                    gap: 10px;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
                    cursor: pointer;
                    .item__icon{
                        .tile-icon{
                            height: 25px;
                            color: rgb(249, 150, 165);
                            &:hover{
                                color: rgb(254, 131, 149);
                            }
                        }
                        
                    }
                    .item__text{
                        font-family: 'Open Sans', sans-serif;
                        font-weight: 700;
                        color: rgb(70, 70, 70);
                    }
                    &:hover{
                        scale: 0.95;
                        background-color: rgb(240, 240, 240);
                        transition: 0.4s;
                    }
                    &:not(:hover){
                        transition: 0.4s;
                    }
                }
            }
            .feature__text{
                width: 75%;
                font-family: 'Open Sans', sans-serif;
                font-size: 16px;
                font-weight: 600;
                line-height: 30px;
                color: rgb(82, 82, 82);
                //background-color: rgba(144, 238, 144, 0.675);
                height: 80%;
                @media screen and (max-width: 1400px) {
                    font-size: 16px;
                }
            }
        }
        .right__container{
            //position: relative;
            img{
                //position: absolute;
                //bottom: -150px;
                //top: 100px;
                height: 600px;
            }
        }
    }
}
.features{
    height: 90vh;
    width: auto;
    background-color: white;
    //background-color: rgb(254, 237, 237);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 0;
    overflow: hidden;
    .title{
        font-size: 50px;
        font-weight: 700;
        color: rgb(89, 89, 89);
        font-family: 'Raleway', sans-serif;
        width: 50%;
        text-align: center;
        margin-bottom: 20px;
        @media screen and (max-width: 1100px) {
            width: 80%;
            margin-top: 5vh;
        }
        @media screen and (max-width: 700px) {
            width: 90%;
            margin-top: 8vh;
            font-size: 7vw;
            margin-bottom: 2vh;
        }
    }
    .sub__title{
        font-family: 'Open Sans', sans-serif;
        width: 40%;
        text-align: center;
        font-size: 16px;
        @media screen and (max-width: 1100px) {
            font-size: 1.8vw;
            width: 70%;
            margin-top: 1vh;
            margin-bottom: 4vh;
        }
        @media screen and (max-width: 700px) {
            width: 80%;
            margin-bottom: 6vh;
            font-size: 3vw;
        }
    }
    .features__container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10%;
        width: 70%;
        //background-color: lightcoral;
        @media screen and (max-width: 1100px) {
            width: 90%;
        }
        .feature__box{
            height: 150px;
            width: 260px;
            border-radius: 5px;
            padding: 15px 20px;
            //background-color: rgb(237, 237, 237);
            box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
            .top__container{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;
                margin-bottom: 20px;
                img{
                    height: 30px;
                }
                .tile-icon{
                    height: 25px;
                    color: rgb(249, 150, 165);
                    &:hover{
                        color: rgb(254, 131, 149);
                    }
                }
                .box__title{
                    font-size: 22px;
                    font-family: 'Varela Round', sans-serif;
                    font-weight: 600;
                }

            }
            .description__container{
                font-size: 16px;
                font-family: 'Poppins', sans-serif;
                font-weight: 300;
                color: rgb(101, 101, 101);
            }
        }
        @media screen and (min-width: 1100px) {
            .feature__box__offset__right{
                position: relative;
                right: -50px;
                
            }
            .feature__box__offset__left{
                position: relative;
                left: -50px;
            }
            .left__container{
                display: flex;
                flex-direction: column;
                gap: 50px;
            }
        }
        @media screen and (max-width: 1100px) {
            .left__container{
                display: flex;
                flex-direction: column;
                gap: 5vh;
                margin-bottom: 5vh;
            }
        }
        .middle__container{
            img{
                margin-left: 30px;
            }
            @media screen and (max-width: 1100px) {
                //display: none;
                order: 3;
            }
            @media screen and (max-width: 700px) {
                //order: 3;
                width: 100px;
            }
        }
        @media screen and (max-width: 1100px) {
            flex-wrap: wrap;
        }
    }
    @media screen and (max-width: 1100px) {
        height: fit-content;
    }
}
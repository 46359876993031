.header{
    //background-color: white;
    background-color: rgb(254, 237, 237);
    height: 77vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    margin: 13vh 0 0 0;
    cursor: default;
    .header__container{
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        ///
        @media screen and (min-width: 1100px) {
            background-color: rgba(247, 247, 247, 0.337);
            background-color: white;
            -webkit-box-shadow: 0px 0px 11px 1px rgba(0,0,0,0.3);
            -moz-box-shadow: 0px 0px 11px 1px rgba(0,0,0,0.3);
            box-shadow: 0px 0px 11px 1px rgba(0,0,0,0.3);
            overflow: hidden;
            width: 80%;
            padding-left: 5%;
            border-radius: 10px;
        }
        
        .left__container{
            flex: 6;
            display: flex;
            flex-direction: column;
            gap: 30px;
            .top__text{
                //font-size: 70px;
                font-size: 5vw;
                //line-height: 80px;
                line-height: 6.2vw;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                color: rgb(28, 28, 44);
                background-color: #FBDA61;
background-image: linear-gradient(45deg, #FBDA61 0%, #FF5ACD 100%);

	            -webkit-background-clip: text;
	            -webkit-text-fill-color: transparent;
                @media screen and (max-width: 1100px){
                    font-size: 10vw;
                    line-height: 12vw;
                    text-align: center;
                }
                @media screen and (max-width: 700px){
                    font-size: 10vw;
                    line-height: 12vw;
                    text-align: center;
                    margin-top: 5vh;
                }
                
            }
            .bottom__text{
                font-family: 'Poppins', sans-serif;
                font-weight: 300;
                width: 75%;
                font-size: 20px;
                color: rgb(77, 77, 77);
                @media screen and (max-width: 1100px){
                    text-align: center;
                    width: 100%;
                    font-size: 3vw;
                    line-height: 5vw;
                    margin-bottom: 5vh;
                }
                @media screen and (max-width: 700px){
                    text-align: center;
                    width: 90%;
                    font-size: 4vw;
                    line-height: 5vw;
                    font-weight: 200;
                    //margin-bottom: 5vh;
                }
                
            }
            @media screen and (max-width: 700px){
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
        .right__container{
            flex: 4;
            position: relative;
            //background-color: azure;
            .header__image{
                //background-color: lightgreen;
                @media screen and (min-width: 1100px) {
                    position: absolute;
                    top: -15vw;
                    right: 15%;
                    //height: 700px;
                    width: 25vw;
                }
                @media screen and (max-width: 700px) {
                    margin-top: 30px;
                    position: absolute;
                    width: 70vw;
                    top: -80px;
                    left: -130px;
                }
                // @media screen and (min-width: 1100px) {
                // }
            }
        }
        @media screen and (max-width: 1100px) {
            flex-direction: column;
            align-items: center;
        }
    }
    @media screen and (max-width: 1100px) {
        //margin-top: 20vh;
        overflow: hidden;
        height: 90vh;
        margin-top: 10vh;
        margin-bottom: 0px;
    }
    @media screen and (max-width: 700px) {
        //margin-top: 20vh;
        overflow: hidden;
        height: 100vh;
        margin: 0;
    }
    @media screen and (min-width: 1100px) {
        margin-bottom: 10vh;
    }
    
}
.about1{
    width: auto;
    //height: 85vh;
    min-height: 100vh;
    //height: fit-content;
    //padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Raleway', sans-serif;
    //background-color: rgb(255, 255, 255);
    background-color: rgb(254, 237, 237);
    cursor: default;
    //background-image: url('https://res.cloudinary.com/dbzzj25vc/image/upload/v1673104221/Quinproc/mesh-gradient_2_g7p2f7.png');
    .heading{
        margin-top: 10vh;
        font-size: 45px;
        font-weight: 700;
        color: rgb(89, 89, 89);
        margin-bottom: 15px;
        @media screen and (max-width: 700px) {
            margin-top: 10vh;
            text-align: center;
            font-size: 8vw;
        }
    }
    .sub__heading{
        font-family: 'Open Sans', sans-serif;
        width: 40%;
        text-align: center;
        font-size: 16px;
        @media screen and (max-width: 1100px) {
            font-size: 1.8vw;
            width: 70%;
            margin-top: 1vh;
            margin-bottom: 4vh;
        }
        @media screen and (max-width: 700px) {
            width: 80%;
            margin-bottom: 2vh;
            font-size: 3vw;
        }
    }
    .all__container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 5vh;
        margin-top: 5vh;
        .team__container{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            //background-color: rgba(240, 128, 128, 0.571);
            width: 65%;
            //gap: 10%;
            @media screen and (max-width: 1300px) {
                width: 80%;
            }
            @media screen and (max-width: 1000px) {
                width: 85%;
            }
            @media screen and (max-width: 900px) {
                width: 95%;
            }
            .member__container{
                display: flex;
                flex-direction: column;
                align-items: center;
                .img__container{
                    img{
                        width: 150px;
                        height: 150px;
                        border-radius: 100%;
                        object-fit: fill;
                    }
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    background-color: lightgreen;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
                }
                .detail__container{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 20px;
                    gap: 8px;
                    .name__container{
                        font-size: 25px;
                        font-weight: 600;
                        cursor: pointer;
                    }
                    .title__container{
                        color: rgb(84, 84, 84);
                    }
                }
            }
        }
    }
}
.navbar{
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    background-color: rgba(243, 243, 243, 0.359);
    //background-color: lightgrey;
    width: 100vw;
    justify-content: center;
    font-smooth: always;
    z-index: 99;
    backdrop-filter: saturate(180%) blur(10px);
    .navbar__container{
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        img{
            height: 8vh;
            cursor: pointer;
            // @media screen and (max-width: 1100px) {
            //     padding: 10px 0;
            // }
        }
        @media screen and (max-width: 1100px) {
            padding: 10px 0;
        }
        .title{
            font-size: 30px;
            font-family: 'Varela Round', sans-serif;
            font-weight: 700;
            cursor: default;
            @media screen and (max-width: 1100px){
                margin-top: 2vw;
                font-size: 6vw;
            }
            // img{
            //     height
            // }
        }
        @media screen and (max-width: 1100px) {
            display: flex;
            justify-content: center;
        }
        .link__container{
            display: flex;
            flex-direction: row;
            gap: 30px;
            .link__item{
                font-family: 'Raleway', sans-serif;
                font-weight: 600;
                color: rgb(106, 106, 106);
                cursor: pointer;
                &:hover{
                    transition: 0.4s;
                    color: rgb(251, 150, 165);
                    //scale: 1.03;
                }
                &:not(:hover){
                    transition: 0.4s;
                }
            }
            @media screen and (max-width: 1100px) {
                display: none;
            }
        }
    }
}